import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import React, { useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function PasswordField({ formikRef, ...props }) {
  const [showPassword, setShowPassword] = React.useState(false);
  const formik = formikRef;
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl>
      <TextField
        label="Senha"
        id="data.password"
        name="data.password"
        type={showPassword ? "text" : "password"}
        fullWidth
        onChange={formik.handleChange}
        margin="dense"
        autoComplete="new-password"
        InputProps={{
          autoComplete: "new-password",
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleShowPassword}
                onMouseDown={handleMouseDownPassword}
                aria-label="toggle password visibility"
                edge="end"
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </FormControl>
  );
}
