import { Schedule } from "@mui/icons-material";
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import React from "react";

const logsMock = [
  {
    id: 1,
    data: "25/1/2025",
    acao: "Ticket Criado por maria X",
  },
  {
    id: 2,
    data: "25/1/2025",
    acao: "Ticket Criado por maria X",
  },
];

function LogTable({ logs }) {
  return (
    <>
      {logs?.length > 0 ? (
        logs.map((log) => (
          <React.Fragment key={log.id}>
            <Stack
              alignItems={"flex-start"}
              direction={"row"}
              spacing={2}
              sx={{ p: 3 }}
            >
              <ListItem
                alignItems="flex-start"
                sx={{ width: "20%", p: (theme) => theme.spacing(1.25, 3) }}
              >
                <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                  <ArticleOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Data" secondary={log.created_at} />
              </ListItem>
              <ListItem
                alignItems="flex-start"
                sx={{ p: (theme) => theme.spacing(1.25, 3) }}
              >
                <ListItemText primary="Ação" secondary={log.log} />
              </ListItem>
            </Stack>
            <Divider />
          </React.Fragment>
        ))
      ) : (
        <Typography variant={"h6"}>Nenhum log encontrado</Typography>
      )}
    </>
  );
}

export default LogTable;
