import React from "react";
import { Stack, Typography } from "@mui/material";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import AppleIcon from "@mui/icons-material/Apple";
import moment from "moment";
import {
  Anchor,
  Block,
  ChildCare,
  DirectionsCar,
  LocationOn,
  ThumbUpAlt,
  WorkHistory,
} from "@mui/icons-material";

const ActivitiesItem = ({ item }) => {
  const statusInfo = [
    {
      name: "Aberto",
      icon: <ChildCare />,
      color: "#00A073",
    },
    {
      name: "Técnico à caminho",
      icon: <DirectionsCar />,
      color: "#008598",
    },
    {
      name: "Técnico chegou",
      icon: <LocationOn />,
      color: "#DF295E",
    },
    {
      name: "Resolvido",
      icon: <ThumbUpAlt />,
      color: "#00A073",
    },
    {
      name: "Pendente",
      icon: <WorkHistory />,
      color: "#F39711",
    },
    {
      name: "Orfão",
      icon: <Anchor />,
      color: "rgba(0,0,0,0.12)",
    },

    {
      name: "Cancelado",
      icon: <Block />,
      color: "#E73145",
    },
    {
      name: "Garantia",
      icon: <Anchor />,
      color: "rgba(0,0,0,0.12)",
    },
  ];

  return (
    <TimelineItem
      sx={{
        "&::before": {
          display: "none",
        },
      }}
    >
      <TimelineSeparator>
        <TimelineDot
          sx={{
            p: "6px",
            m: 0,
            bgcolor: statusInfo[item.ticket_status_id - 1].color,
            color: "white",
          }}
        >
          {statusInfo[item.ticket_status_id - 1].icon}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent
        sx={{
          p: (theme) => theme.spacing(0.5, 0),
          ml: 2.5,
        }}
      >
        <Typography variant="h5" mb={0.5}>
          OS: #{item.os}
        </Typography>
        <Typography variant={"h6"} color={"text.secondary"}>
          Status: {statusInfo[item.ticket_status_id - 1].name}
        </Typography>
        <Stack direction="row" spacing={2}>
          <Typography variant={"h6"} color={"text.secondary"}>
            Criado em: {moment(item.created_at).format("DD/MM/YYYY")}
          </Typography>
          {item.data_de_resolucao && (
            <Typography variant={"h6"} color={"text.secondary"}>
              Resolvido em:{" "}
              {moment(item.data_de_resolucao).format("DD/MM/YYYY")}
            </Typography>
          )}
        </Stack>
        {item.tecnico && (
          <Typography variant={"h6"} color={"text.secondary"}>
            Técnico responsável: {item.tecnico.name}
          </Typography>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};
/* Todo item prop define */
export default ActivitiesItem;
