import React, { useEffect, useState } from "react";
import ProjectItem from "./ProjectItem";
import { Typography } from "@mui/material";

const ProjectsList = ({ data, type }) => {
  const fields = {
    todos: [
      { value: "equipamento_nome", label: "Máquina" },
      { value: "tempo_chamado", label: "Tempo total" },
      { value: "created_at", label: "Abertura", format: "DD/MM/YYYY" },
      { value: "tecnico", label: "Técnico", subfield: "name" },
      {
        value: "client",
        label: "Solicitante",
        prerequisites: { isBackOffice: "BackOffice" },
      },
      { value: "empresa", label: "Empresa" },
      { value: "ocorrencia_reportada", label: "Ocorrência" },
    ],
    abertos: [
      { value: "equipamento_nome", label: "Máquina" },
      { value: "tempo_chamado", label: "Tempo etapa" },
      { value: "created_at", label: "Abertura", format: "DD/MM/YYYY" },
      { value: "tecnico", label: "Técnico", subfield: "name" },
      {
        value: "client",
        label: "Solicitante",
        prerequisites: { isBackOffice: "BackOffice" },
      },
      { value: "empresa", label: "Empresa" },
      { value: "ocorrencia_reportada", label: "Ocorrência" },
    ],
    tecnico_a_caminho: [
      { value: "equipamento_nome", label: "Máquina" },
      { value: "tempo_chamado", label: "Tempo etapa" },
      { value: "created_at", label: "Abertura", format: "DD/MM/YYYY" },
      { value: "tecnico", label: "Técnico", subfield: "name" },
      {
        value: "client",
        label: "Solicitante",
        prerequisites: { isBackOffice: "BackOffice" },
      },
      { value: "empresa", label: "Empresa" },
      { value: "ocorrencia_reportada", label: "Ocorrência" },
    ],
    tecnico_chegou: [
      { value: "equipamento_nome", label: "Máquina" },
      { value: "tempo_chamado", label: "Tempo etapa" },
      { value: "created_at", label: "Abertura", format: "DD/MM/YYYY" },
      { value: "tecnico", label: "Técnico", subfield: "name" },
      {
        value: "client",
        label: "Solicitante",
        prerequisites: { isBackOffice: "BackOffice" },
      },
      { value: "empresa", label: "Empresa" },
      { value: "ocorrencia_reportada", label: "Ocorrência" },
    ],
    resolvidos: [
      { value: "equipamento_nome", label: "Máquina" },
      { value: "tecnico", label: "Técnico", subfield: "name" },
      { value: "created_at", label: "Abertura", format: "DD/MM/YYYY" },
      {
        value: "client",
        label: "Solicitante",
        prerequisites: { isBackOffice: "BackOffice" },
      },
      { value: "empresa", label: "Empresa" },
      { value: "ocorrencia_reportada", label: "Ocorrência" },
    ],
    pendentes: [
      { value: "equipamento_nome", label: "Máquina" },
      { value: "tempo_chamado", label: "Tempo etapa" },
      { value: "created_at", label: "Abertura", format: "DD/MM/YYYY" },
      { value: "tecnico", label: "Técnico", subfield: "name" },
      {
        value: "client",
        label: "Solicitante",
        prerequisites: { isBackOffice: "BackOffice" },
      },
      { value: "empresa", label: "Empresa" },
      { value: "ocorrencia_reportada", label: "Ocorrência" },
    ],
    orfao: [
      { value: "equipamento_nome", label: "Máquina" },
      { value: "tempo_chamado", label: "Tempo etapa" },
      { value: "created_at", label: "Abertura", format: "DD/MM/YYYY" },
      {
        value: "client",
        label: "Solicitante",
        prerequisites: { isBackOffice: "BackOffice" },
      },
      { value: "empresa", label: "Empresa" },
      { value: "ocorrencia_reportada", label: "Ocorrência" },
    ],
    cancelados: [
      { value: "equipamento_nome", label: "Máquina" },
      { value: "created_at", label: "Abertura", format: "DD/MM/YYYY" },
      {
        value: "client",
        label: "Solicitante",
        prerequisites: { isBackOffice: "BackOffice" },
      },
      { value: "empresa", label: "Empresa" },
      { value: "ocorrencia_reportada", label: "Ocorrência" },
    ],
    garantia: [
      { value: "equipamento_nome", label: "Máquina" },
      { value: "created_at", label: "Abertura", format: "DD/MM/YYYY" },
      {
        value: "client",
        label: "Solicitante",
        prerequisites: { isBackOffice: "BackOffice" },
      },
      { value: "empresa", label: "Empresa" },
      { value: "ocorrencia_reportada", label: "Ocorrência" },
    ],
  };

  useEffect(() => {
    console.log("aqui222", data);
  }, [data]);

  return (
    <React.Fragment>
      {data.length > 0 ? (
        <>
          {data.map((project, key) =>
            project.tecnico ? (
              <ProjectItem
                key={key}
                item={project}
                fields={fields[type]}
                isHighlighted={true}
              />
            ) : (
              <ProjectItem key={key} item={project} fields={fields[type]} />
            )
          )}
        </>
      ) : (
        <Typography variant={"h4"} mb={3}>
          Nenhum chamado para listar
        </Typography>
      )}
    </React.Fragment>
  );
};

export default ProjectsList;
