import React, { useEffect, useState } from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {
  Accordion,
  AccordionDetails,
  Avatar,
  Button,
  Card,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import Div from "@jumbo/shared/Div";
import { history } from "app/redux/store";
import moment from "moment";

const ProjectItem = ({ item, fields, isHighlighted }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [timer, setTimer] = useState("...");

  const handleClick = () => {
    history.push(`/chamados/detalhes/${item.id}`);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      let seconds = moment().diff(moment(item.created_at), "s");
      let minutes = Math.floor(seconds / 60);
      seconds -= minutes * 60;
      const hours = Math.floor(minutes / 60);
      minutes -= hours * 60;

      setTimer(
        `${hours < 10 ? `0${hours}` : hours}:${
          minutes < 10 ? `0${minutes}` : minutes
        }:${seconds < 10 ? `0${seconds}` : seconds}`
      );
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function printItemValue(field) {
    if (!item[field.value]) return "N/A";

    if (field.format) {
      return moment(item[field.value]).format(field.format);
    }

    if (field.subfield) {
      return item[field.value][field.subfield];
    }

    if (field.prerequisites) {
      let result = null;
      Object.keys(field.prerequisites).map((key) => {
        if (item[key]) {
          result = field.prerequisites[key];
          return;
        }
      });

      if (result) return result;
    }

    return item[field.value];
  }

  return (
    <Card sx={{ mb: 1 }}>
      <Accordion
        expanded={expanded}
        onChange={handleClick}
        square
        sx={{ borderRadius: 2 }}
      >
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            px: 3,
            flex: 1,
            flexDirection: "row-reverse",
            minHeight: 90,

            "& .MuiAccordionSummary-content": {
              alignItems: "center",

              "&.Mui-expanded": {
                margin: "12px 0",
              },
            },
            ".MuiAccordionSummary-expandIconWrapper": {
              borderRadius: 1,
              border: 1,
              color: "text.secondary",
              borderColor: "divider",
              transform: "none",
              height: 28,
              width: 28,
              alignItems: "center",
              justifyContent: "center",
              mr: 1,

              "&.Mui-expanded": {
                transform: "none",
                color: "primary.main",
                borderColor: "primary.main",
              },

              "& svg": {
                fontSize: "1.25rem",
              },
            },
            border: isHighlighted ? "2px solid lightgreen" : "none",
            borderRadius: isHighlighted ? "11px" : "none",
          }}
        >
          <Div
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              flex: 1,
            }}
          >
            <Div
              sx={{
                mr: { xs: 0, md: 2 },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #0DA594",
                bgcolor: "#FFF",
                borderRadius: "5px",
                p: (theme) => theme.spacing(1, 2),
                px: 1,
                mb: { xs: 2, md: 0 },
              }}
            >
              <Typography variant={"h6"} color="#0DA594" mb={0}>
                {item.os.slice(0, 7)}
              </Typography>
            </Div>

            {fields.map((field, index) => (
              <Div
                sx={{
                  display: {
                    xs: [0, 2].includes(index) ? "flex" : "none",
                    md: [3, 5].includes(index) ? "none" : "flex",
                    lg: "flex",
                  },
                  flex: 1,
                  flexDirection: "column",
                  alignItems: { xs: "center", md: "flex-start" },
                }}
              >
                <Typography
                  fontSize={"12px"}
                  variant={"h6"}
                  color={"text.secondary"}
                  mb={0.25}
                >
                  {field.label}
                </Typography>
                <Typography variant={"body1"}>
                  {field.value == "tempo_chamado"
                    ? timer
                    : printItemValue(field)}
                </Typography>
              </Div>
            ))}
          </Div>
        </AccordionSummary>
      </Accordion>
    </Card>
  );
};

export default ProjectItem;
