import React from "react";
import JumboNavIdentifier from "@jumbo/components/JumboVerticalNavbar/JumboNavIdentifier";
import { useJumboLayoutSidebar } from "@jumbo/hooks";
import { SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import List from "@mui/material/List";
import PropTypes from "prop-types";
import ModalRelatorio from "app/components/ModalRelatorio";
import { useDispatch, useSelector } from "react-redux";
import { setRelatorio } from "app/redux/modules/settings/action";
import ModalPayment from "app/components/ModalPayment";

const JumboVerticalNavbar = ({ items, translate }) => {
  const { settings, auth } = useSelector((state) => state);
  const dispatch = useDispatch();

  const { sidebarOptions } = useJumboLayoutSidebar();

  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
  }, [sidebarOptions.view, sidebarOptions.open]);

  function setOpen(value) {
    dispatch(setRelatorio(value));
  }

  console.log("oque deu?", settings);

  return (
    <>
      {settings.excel === "/relatorios" && (
        <ModalRelatorio open={settings.openRelatorio} setOpen={setOpen} />
      )}
      {settings.excel === "/pagamentos" && (
        <ModalPayment open={settings.openRelatorio} setOpen={setOpen} />
      )}
      <List
        disablePadding
        sx={{
          mr: isMiniAndClosed ? 0 : 2,
          pb: 2,
        }}
      >
        {items.map((item, index) => {
          if (item?.restricted && auth?.data?.user?.roles[0].name === "SAC")
            return null;
          return <JumboNavIdentifier translate item={item} key={index} />;
        })}
      </List>
    </>
  );
};

JumboVerticalNavbar.defaultProps = {
  items: PropTypes.array,
  translate: false,
};

export default JumboVerticalNavbar;
