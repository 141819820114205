import React from "react";
import { TimelineItem } from "@mui/lab";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import {
  LocationOn,
  MiscellaneousServices,
  Today,
  EventAvailable,
  Schedule,
  Person,
  Business,
  CoffeeMaker,
  ReportProblem,
  Notes,
  Engineering,
  TipsAndUpdates,
  SettingsSuggest,
  Build,
  Photo,
  QrCode,
} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import moment from "moment";
import QRCode from "react-qr-code";

const ScheduleItem = ({ item }) => {
  const ticketsStatus = [
    "Aberto",
    "Técnico a caminho",
    "Técnico chegou",
    "Resolvido",
    "Pendente",
    "Orfão",
    "Cancelado",
    "Garantia",
  ];

  const verityDateField = (date) => {
    return (
      (Array.isArray(date) && date.length > 0) || (!Array.isArray(date) && date)
    );
  };

  const calculateTime = (start, end) => {
    if (!verityDateField(end)) return "N/A";

    start = new Date(start);
    end = new Date(end.date || end);

    const durationInMilliseconds = end - start;
    const durationInSeconds = durationInMilliseconds / 1000;
    const durationInMinutes = durationInSeconds / 60;
    const durationInHours = durationInMinutes / 60;
    const days = Math.floor(durationInHours / 24);

    const hours = Math.floor(durationInHours % 24);
    const minutes = Math.floor(durationInMinutes % 60);

    const total = `${days} dias, ${hours} horas, ${minutes} minutos`;
    return total;
  };
  return (
    <TimelineItem
      sx={{
        "&::before": {
          display: "none",
        },
      }}
    >
      <List disablePadding sx={{ width: "100%" }}>
        <ListItem
          alignItems="flex-start"
          sx={{ p: (theme) => theme.spacing(1.25, 3) }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4} container alignItems="center">
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Today />
              </ListItemIcon>
              <ListItemText
                primary="Data de criação"
                secondary={moment(item.created_at).format("DD/MM/YYYY HH:mm")}
              />
            </Grid>
            <Grid item xs={4} container alignItems="center">
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <EventAvailable />
              </ListItemIcon>
              <ListItemText
                primary="Data da resolução"
                secondary={
                  verityDateField(item.data_de_resolucao)
                    ? moment(
                        item.data_de_resolucao.date || item.data_de_resolucao
                      ).format("DD/MM/YYYY HH:mm")
                    : "N/A"
                }
              />
            </Grid>

            <Grid item xs={4} container alignItems="center">
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Schedule />
              </ListItemIcon>
              <ListItemText
                primary="Tempo do atendimento"
                secondary={
                  calculateTime(item.created_at, item.data_de_resolucao) ||
                  "N/A"
                }
              />
            </Grid>
          </Grid>
        </ListItem>
        <Divider component="li" />

        <ListItem
          alignItems="flex-start"
          sx={{ p: (theme) => theme.spacing(1.25, 3) }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4} container alignItems="center">
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Person />
              </ListItemIcon>
              <ListItemText
                primary="Cliente"
                secondary={item.client || "BackOffice"}
              />
            </Grid>
            <Grid item xs={4} container alignItems="center">
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <CoffeeMaker />
              </ListItemIcon>
              <ListItemText
                primary="Segmento"
                secondary={item.segmento || "N/A"}
              />
            </Grid>
            <Grid item xs={4} container alignItems="center">
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <CoffeeMaker />
              </ListItemIcon>
              <ListItemText
                primary="Equipamento modelo"
                secondary={item.equipamento_modelo || "N/A"}
              />
            </Grid>
          </Grid>
        </ListItem>
        <Divider component="li" />

        <ListItem
          alignItems="flex-start"
          sx={{ p: (theme) => theme.spacing(1.25, 3) }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4} container alignItems="center">
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Business />
              </ListItemIcon>
              <ListItemText primary="Empresa" secondary={item.empresa} />
            </Grid>
            <Grid item xs={4} container alignItems="center">
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <CoffeeMaker />
              </ListItemIcon>
              <ListItemText
                primary="Equipamento"
                secondary={`${item.equipamento_nome} (${item.qrcode})`}
              />
            </Grid>
            <Grid item xs={4} container alignItems="center">
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <LocationOn />
              </ListItemIcon>
              <ListItemText primary="Endereço" secondary={item.endereco} />
            </Grid>
          </Grid>
        </ListItem>
        <Divider component="li" />

        <ListItem
          alignItems="flex-start"
          sx={{ p: (theme) => theme.spacing(1.25, 3) }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4} container alignItems="center">
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Person />
              </ListItemIcon>
              <ListItemText
                primary="Solicitante"
                secondary={
                  !item.cliente_excel ? "BackOffice" : item.cliente_excel?.name
                }
              />
            </Grid>
            <Grid item xs={4} container alignItems="center">
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Person />
              </ListItemIcon>
              <ListItemText
                primary="Telefone do solicitante"
                secondary={
                  !item.cliente_excel
                    ? "BackOffice"
                    : item.cliente_excel?.cel || "N/A"
                }
              />
            </Grid>
            <Grid item xs={4} container alignItems="center">
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <ReportProblem />
              </ListItemIcon>
              <ListItemText
                primary="Ocorrência reportada"
                secondary={item.ocorrencia_reportada}
              />
            </Grid>
          </Grid>
        </ListItem>
        <Divider component="li" />

        <ListItem
          alignItems="flex-start"
          sx={{ p: (theme) => theme.spacing(1.25, 3) }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4} container alignItems="center">
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Engineering />
              </ListItemIcon>
              <ListItemText
                primary="Técnico responsável"
                secondary={item.tecnico_excel ? item.tecnico_excel.name : "N/A"}
              />
            </Grid>

            <Grid item xs={4} container alignItems="center">
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Notes />
              </ListItemIcon>
              <ListItemText
                primary="Observação"
                secondary={item.comment || "N/A"}
              />
            </Grid>
            <Grid item xs={4} container alignItems="center">
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Notes />
              </ListItemIcon>
              <ListItemText
                primary="Observações do técnico"
                secondary={item.observacao_do_tecnico || "N/A"}
              />
            </Grid>
          </Grid>
        </ListItem>
        <Divider component="li" />

        <ListItem
          alignItems="flex-start"
          sx={{ p: (theme) => theme.spacing(1.25, 3) }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4} container alignItems="center">
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <TipsAndUpdates />
              </ListItemIcon>
              <ListItemText
                primary="Diagnóstico de problemas"
                secondary={item.diagnostico_problemas || "N/A"}
              />
            </Grid>
            <Grid item xs={4} container alignItems="center">
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <SettingsSuggest />
              </ListItemIcon>
              <ListItemText
                primary="Diagnóstico de peças"
                secondary={item.diagnostico_pecas || "N/A"}
              />
            </Grid>
            <Grid item xs={4} container alignItems="center">
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <CoffeeMaker />
              </ListItemIcon>
              <ListItemText
                primary="Funcionamento da máquina"
                secondary={item.funcionamento_maquina || "N/A"}
              />
            </Grid>
          </Grid>
        </ListItem>
        <Divider component="li" />

        <ListItem
          alignItems="flex-start"
          sx={{ p: (theme) => theme.spacing(1.25, 3) }}
        >
          <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
            <Photo />
          </ListItemIcon>

          <Stack>
            <ListItemText
              primary="Fotos do equipamento"
              secondary={
                item.mau_uso_images && item.mau_uso_images !== "0" ? "" : "N/A"
              }
            />

            {item.mau_uso_images && (
              <Stack flexDirection="row">
                {item.mau_uso_images.split(";").map((image) => {
                  if (image === "0") return null;
                  return (
                    <a
                      href={
                        item.ticket_id
                          ? `https://storage.googleapis.com/uteky/tickets/${item.ticket_id}/${image}`
                          : `https://storage.googleapis.com/uteky/tickets/${item.id}/${image}`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="Imagem Técnico"
                        style={{
                          width: 120,
                          objectFit: "contain",
                          border: "1px solid #ddd",
                          margin: "0 3px",
                          cursor: "pointer",
                          height: 110,
                          padding: 0,
                          borderRadius: 10,
                        }}
                        src={
                          item.ticket_id
                            ? `https://storage.googleapis.com/uteky/tickets/${item.ticket_id}/${image}`
                            : `https://storage.googleapis.com/uteky/tickets/${item.id}/${image}`
                        }
                      />
                    </a>
                  );
                })}
              </Stack>
            )}
          </Stack>
        </ListItem>
        <Divider component="li" />

        <ListItem
          alignItems="flex-start"
          sx={{ p: (theme) => theme.spacing(1.25, 3) }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} container alignItems="center">
              <ListItemIcon sx={{ minWidth: 36, color: "text.primary" }}>
                <Build />
              </ListItemIcon>
              <ListItemText
                primary="Status"
                secondary={ticketsStatus[item.ticket_status_id - 1]}
              />
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </TimelineItem>
  );
};

export default ScheduleItem;
