import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "app/layouts/layouts";
import { useSelector } from "react-redux";
import {
  Typography,
  Stack,
  Skeleton,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Backdrop,
  CircularProgress,
  Divider,
  ListItemButton,
  Button,
} from "@mui/material";
import { useState } from "react";
import api from "../../services/config";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboContent from "@jumbo/components/JumboContent";
import { ExpandLess, ExpandMore, DoNotDisturbOn } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import Lottie from "lottie-react";
import emptyAnimation from "../../assets/emptyAnimation.json";
import ModalNovoChamado from "app/components/ModalNovoChamado";
import ModalAtribuir from "app/components/ModalAtribuir";

const FilaChamadosDetalhes = () => {
  const { auth } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [user, setUser] = useState(null);
  const [fila, setFila] = useState([]);
  const [pendingsTickets, setPendingsTickets] = useState([]);

  const [novoChamado, setNovoChamado] = useState(false);
  const [atribuir, setAtribuir] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { id } = useParams();

  const { setActiveLayout } = useJumboApp();
  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);

  React.useEffect(() => {
    fetchFila();
  }, [refresh]);

  async function fetchFila() {
    setLoading(true);
    try {
      const { data } = await api.get(`/api/v2/dashboard/fila/${id}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });

      console.log("DATA > > > ", data);
      setUser(data.user);
      setFila(data.user_queue);
      setPendingsTickets(data.pending_tickets);
    } catch (error) {
      console.log("ERROR > > ", error);
    }
    setLoading(false);
  }

  async function cancel_ticket(ticket_id, item_id) {
    setUpdating(true);
    try {
      const { data } = await api.put(
        `/api/v1/cancel_ticket/${ticket_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      await remove_from_queue(ticket_id, item_id);
    } catch (error) {
      console.log("ERROR > > > ", error);
    }
    setUpdating(false);
    setRefresh((prev) => !prev);
  }

  async function remove_from_queue(ticket_id, item_id) {
    try {
      const { data } = await api.delete(`/api/v1/fila/item/${item_id}`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
    } catch (error) {
      console.log("ERROR > > > ", error);
    }
  }

  async function upPriority(fila_id, priority) {
    setUpdating(true);
    try {
      const { data } = await api.put(
        `api/v2/dashboard/fila/up-priority/${fila_id}`,
        { user_id: id },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      const temp = fila;

      temp[priority - 1].priority = priority - 1;
      temp[priority - 2].priority = priority;

      temp = temp.sort((a, b) => {
        if (a.priority > b.priority) return 1;

        return -1;
      });

      setFila(temp);
    } catch (error) {
      console.log("ERROR UP PRIORITY > > ", error);
    }
    setUpdating(false);
  }

  async function downPriority(fila_id, priority) {
    setUpdating(true);
    try {
      const { data } = await api.put(
        `api/v2/dashboard/fila/down-priority/${fila_id}`,
        { user_id: id },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      const temp = fila;

      temp[priority - 1].priority = priority + 1;
      temp[priority].priority = priority;

      temp = temp.sort((a, b) => {
        if (a.priority > b.priority) return 1;

        return -1;
      });

      setFila(temp);
    } catch (error) {
      console.log("ERROR DOWN PRIORITY > > ", error);
    }
    setUpdating(false);
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={updating}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <ModalNovoChamado
        open={novoChamado}
        setOpen={setNovoChamado}
        title="Adicionar Chamado"
        user_id={id}
        refresh={() => setRefresh((prev) => !prev)}
      />

      <ModalAtribuir
        open={atribuir}
        setOpen={setAtribuir}
        pendingsTickets={pendingsTickets}
        userId={id}
        refresh={() => setRefresh(!refresh)}
      />

      {loading ? (
        <>
          <Stack direction="row" spacing={1}>
            <Skeleton
              variant="rectangular"
              style={{ flex: 1, height: "300px", borderRadius: 10 }}
            />
          </Stack>

          <Stack spacing={1} marginTop={1}>
            <Skeleton
              variant="rectangular"
              style={{ height: "500px", borderRadius: 10 }}
            />
          </Stack>
        </>
      ) : (
        <>
          <JumboCardQuick noWrapper>
            <JumboContent
              bgColor={["#03ce97", "#02af98"]}
              sx={{ color: "common.white", pb: 1 }}
            >
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                pt={6}
                px={3}
                justifyContent={"space-between"}
              >
                <Typography variant={"h1"} color={"common.white"}>
                  Fila de chamados: {user ? user.name : ""}
                </Typography>
                {auth.data.user.roles[0].name !== "SAC" && (
                  <Stack flexDirection={"row"}>
                    <Button
                      variant="contained"
                      color="info"
                      sx={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                      onClick={() => setNovoChamado(true)}
                    >
                      Novo
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                      onClick={() => setAtribuir(true)}
                    >
                      Atribuir
                    </Button>
                  </Stack>
                )}
              </Stack>
            </JumboContent>
            <JumboContent sx={{ p: 3 }}>
              {fila.length > 0 ? (
                <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                  {fila.map((item, index) => (
                    <>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar style={{ backgroundColor: "#0DA594" }}>
                            {item.priority}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          sx={{
                            textAlign: "start",
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                          }}
                          primary="OS"
                          secondary={item.ticket?.os}
                        />
                        <ListItemText
                          sx={{
                            textAlign: "start",
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                          }}
                          primary="Solicitante"
                          secondary={
                            item.ticket.isBackOffice
                              ? "BackOffice"
                              : item.ticket.client
                          }
                        />
                        <ListItemText
                          sx={{
                            textAlign: "start",
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            paddingRight: 5,
                          }}
                          primary="Endereço"
                          secondary={item.ticket.endereco}
                        />
                        <ListItemText
                          sx={{
                            textAlign: "start",
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                          }}
                          primary="Tempo"
                          secondary={item.ticket.tempo_chamado || "N/A"}
                        />
                        <Stack
                          alignSelf="center"
                          alignItems="center"
                          justifyContent="center"
                        >
                          {index != 0 && (
                            <ListItemButton
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                pt: 0,
                                pb: 0,
                              }}
                              onClick={() => upPriority(item.id, item.priority)}
                            >
                              <ExpandLess />
                            </ListItemButton>
                          )}
                          {index != fila.length - 1 && (
                            <ListItemButton
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                pt: 0,
                                pb: 0,
                              }}
                              onClick={() =>
                                downPriority(item.id, item.priority)
                              }
                            >
                              <ExpandMore />
                            </ListItemButton>
                          )}
                        </Stack>
                        <ListItemButton
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => cancel_ticket(item.ticket_id, item.id)}
                        >
                          <DoNotDisturbOn color="error" />
                        </ListItemButton>
                      </ListItem>
                      {index != fila.length - 1 && (
                        <Divider variant="inset" component="li" />
                      )}
                    </>
                  ))}
                </List>
              ) : (
                <Stack alignItems="center">
                  <Lottie animationData={emptyAnimation} />
                  <Typography sx={{ mt: 2 }}>
                    Nenhum ticket na fila desse técnico
                  </Typography>
                </Stack>
              )}
            </JumboContent>
          </JumboCardQuick>
        </>
      )}
    </>
  );
};

export default FilaChamadosDetalhes;
