export const ticketsStatus = {
  abertos: 1,
  tecnico_a_caminho: 2,
  tecnico_chegou: 3,
  resolvidos: 4,
  pendentes: 5,
  orfao: 6,
  cancelados: 7,
  garantia: 8,
};
